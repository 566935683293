<template>
<div>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-clipboard"></i> Shown Summary Report</h2>

        <div class="row listing">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-inline">
                            <div class="form-group mr-1">
                                <input v-on:keyup.enter="load" type="date" v-model="filter.from" class="form-control date" placeholder="From"/>
                            </div>
                            <div class="form-group mr-1">
                                <input v-on:keyup.enter="load" type="date" v-model="filter.to" class="form-control date" placeholder="To"/>
                            </div>
                            
                            <button type="button" class="btn btn-secondary mr-1" @click="load"><i class="fa fa-search" title="Search"></i></button>
                            <!--<button type="button" @click="exportToExcel()" class="btn btn-primary mr-1">Export</button>-->
                        </div>
                    </div>
                </div>
                <hr/>

                <div class="listing">
                    <table class="table table-hover bg-light text-dark">
                        <thead>
                        <tr>
                            <td>Total</td>
                            <td>Matched</td>
                            <td>M %</td>
                            <td>Shown</td>
                            <td>S %</td>
                            <td>Capped</td>
                            <td>C %</td>
                            <td>Date</td>
                            <td align="right"></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data in items.data" v-bind:key="data._id">
                            <td>{{data.data.summary.Total}}</td>
                            <td>{{data.data.summary.Matched}}</td>
                            <td>{{data.data.summary.M}}</td>
                            <td>{{data.data.summary.Shown}}</td>
                            <td>{{data.data.summary.S}}</td>
                            <td>{{data.data.summary.Capped}}</td>
                            <td>{{data.data.summary.C}}</td>
                            <td>{{data.created_ts|userTime(user.timezone)}}</td>
                            <td align="right">
                                <div class="btn-group icons">
                                    <router-link class="btn btn-info" :to="{path:`/proxy/report/${data._id}`}" target="_blank"><span class="fa fa-signal" title="Report"></span></router-link>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="row">
                        <div class="col-12 col-sm-9">
                            <v-pagination
                                v-model="page"
                                :pageCount="Math.ceil(items.total/max)"
                                :classes="bootstrapPaginationClasses"
                                :labels="customLabels"
                                @input="load"></v-pagination>
                        </div>
                        <div class="col-12 col-sm-3 text-right">
                            <span>{{items.data.length * Number(page|1)}}</span> of <span>{{items.total}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import axios from "axios";
import vPagination from "vue-plain-pagination";
//import swal from "sweetalert";
export default {
    name: "shown",
    props: ['user'],
    components: {
        vPagination
    },
    data: function () {
        return {
            action:'',
            campaign:null,
            editor: false,
            item: {},
            refreshData:'off',
            options: [
                'foo',
                'bar',
                'baz'
            ],
            items:[],
            filter:{},
            report:{today:'',},
            providers:[],
            stat:{},
            interval:'',
            states:[],
            max: 25,
            page: 1,
            status:{active:'active', pending:'pending',done:'done',proxy:'proxy'},
            cities:[],
            preloader: false,
            bootstrapPaginationClasses: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'
            },
            customLabels: {
                first: 'First',
                prev: 'Previous',
                next: 'Next',
                last: 'Last'
            }
        }
    },
    created: function(){
        this.load();
    },
    methods:{

        load: function(){
            //Load the campaign
            var page
            if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                //Pagination triggered event
                console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
            } else
                page = 0;

            var request = {'apikey': this.$root.apikey};
            request.page = page
            request.max = this.max

            this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/shownrate`, {params:request}).then(function(resp){
                this.items = (resp.data && resp.data)? resp.data : null;
                this.$root.preloader = false;
            }.bind(this));
        }
    }
}
</script>

<style scoped>

</style>
